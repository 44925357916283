<template>
  <div class="text-bb-text-default px-6 py-4 bg-white rounded-lg shadow-md w-full h-100">
    <div class="flex items-center justify-between">
      <div class="flex items-center gap-4">
        <div class="h-10 w-10 rounded-lg bg-neutral-0 text-bb-brand-purple">
          <ic-donut-chart class="my-auto mx-auto h-full" />
        </div>
        <h5 class="h5 text-bb-text-default">Share of Search</h5>
      </div>

      <div class="flex items-center gap-4">
        <div
          v-if="showResetIcon"
          @click="resetChartData()"
        >
          <ic-reset class="text-bb-brand-purple cursor-pointer" />
        </div>
        <new-tooltip direction="bottom-start">
          <ic-info class="text-bb-disabled-buttons" />
          <template #content>
            <div class="w-60">
              <p>
                This chart shows the percentage of organic searches a brand receives compared to total industry searches
                on Google Search.
              </p>
            </div>
          </template>
        </new-tooltip>
      </div>
    </div>
    <div class="py-10">
      <doughnut-chart
        ref="chart"
        chart-id="share-of-search"
        :chart-data="chartData"
        @update-selected-legend-count="updateSelectedLegendCount"
      />
    </div>
  </div>
</template>

<script>
import IcInfo from '@/components/icon/ic-info.vue'
import IcDonutChart from '@/components/icon/brightbid/ic-donut-chart.vue'
import IcReset from '@/components/icon/ic-reset.vue'
import NewTooltip from '@/components/alert/NewTooltip.vue'
import DoughnutChart from '@/views/site/search/competitor_monitoring/overview/DoughnutChart.vue'

export default {
  name: 'ShareOfSearch',
  components: { IcDonutChart, IcInfo, NewTooltip, IcReset, DoughnutChart },
  props: {
    topCompetitors: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showResetIcon: false,
    }
  },
  computed: {
    chartData() {
      return this.topCompetitors.map(item => {
        return {
          label: item.competitor,
          percentage: item.marketSharePercentage,
          isOwn: item.isOwn,
        }
      })
    },
  },
  methods: {
    resetChartData() {
      this.$refs.chart.resetChartData()
    },
    updateSelectedLegendCount(count) {
      this.showResetIcon = count > 0
    },
  },
}
</script>

<style scoped lang="scss"></style>
